<template>
    <div>
        <div class="box-header with-border mb-3">
            <h3>
                Добавить отзыв

                <span class="modal-close-button pull-right" slot="top-right" title="Закрыть окно"
                      v-on:click="EventBus.$emit('hide-' + $options.name)">
                    <i class="fas fa-window-close"></i>
                </span>
            </h3>
        </div>

        <div class="box-body">
            <form @keydown="form_modal.onKeydown($event)" @submit.prevent="saveReview">

                <div class="form-group" v-bind:class="{ 'has-error': form_modal.errors.has('title') }">
                    <label for="rating">
                        Рейтинг
                        <span class="req"/>
                    </label>
                    <select class="form-control"
                            name="rating"
                            id="rating"
                            required
                            v-on:change="setRating($event)">
                        <option :value="null" class="text-muted">
                            Рейтинг
                        </option>
                        <option v-for="rating_value in rating_values"
                                :value="rating_value">
                            {{ rating_value }}
                        </option>
                    </select>
                </div>

                <div class="form-group" v-bind:class="{ 'has-error': form_modal.errors.has('name') }">
                    <div class="row">
                        <div class="col-12">
                            <label for="name">
                                Имя
                                <span class="req"/>
                            </label>
                            <input class="form-control"
                                   id="name"
                                   name="name"
                                   placeholder="Имя"
                                   required
                                   type="text"
                                   v-model="form_modal.name">
                            <HasError :form="form_modal" field="name"/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-12" v-bind:class="{ 'has-error': form_modal.errors.has('review') }">
                            <label for="review">
                                Отзыв
                                <span class="req"/>
                            </label>

                            <textarea class="form-control"
                                      id="review"
                                      placeholder="Текст отзыва"
                                      title="Текст отзыва"
                                      rows="4"
                                      required
                                      v-model="form_modal.review">
                            </textarea>
                            <HasError field="review" :form="form_modal"/>
                        </div>
                    </div>
                </div>

                <br/>

                <AlertErrors :form="form_modal" message="Ошибки валидации. Проверьте введённые данные."/>

                <Button :disabled="!validateData || form_modal.busy" :form="form_modal"
                        :loading="form_modal.busy" class="btn btn-lg btn-success btn-submit pull-right">
                    <i class="fa fa-save"></i>
                </Button>

                <button :disabled="form_modal.busy" class="btn btn-lg btn-default pull-right"
                        style="min-width: 60px; margin-right: 10px;" title="Отменить и закрыть окно"
                        type="button" v-on:click="EventBus.$emit('hide-' + $options.name)">
                    <i class="far fa-window-close"></i>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
    import Form from 'vform';

    Form.axios = API.apiClient;

    export default {
        name: 'create-review',

        components: {
            Button, HasError, AlertError, AlertErrors,
        },

        props: {},

        data: () => ({
            form_modal: new Form({
                name: null,
                rating: null,
                review: null,
            }),

            rating_values: [
                1, 2, 3, 4, 5
            ],
        }),

        async created() {
            try {
                this.EventBus.$emit('is_loaded');
            } catch (error) {
                errorHandler(error);
            }
        },

        computed: {
            validateData: function () {
                return this.form_modal.name && this.form_modal.rating && this.form_modal.review;
            },
        },

        methods: {
            setRating(event) {
                this.form_modal.rating = event.target.value !== '' ? parseInt(event.target.value) : null;
            },

            async saveReview() {
                await this.form_modal.post('/reviews/add').then(() => {
                    showSuccess();

                    this.EventBus.$emit('update-reviews');

                    this.form_modal.reset();

                    this.EventBus.$emit('hide-' + this.$options.name);
                }).catch((error) => {
                    errorHandler(error);
                });
            },
        },
    }

</script>